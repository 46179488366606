<template>
  <IndexPage />
</template>

<script>
import IndexPage from "@/components/IndexPage.vue";

export default {
  name: "notfound",
  components: {
    IndexPage,
  },
  async setup() {},
};
</script>
